document.addEventListener("turbolinks:load", function () {
  const button = document.getElementById("rich_text_toggler");
  const richTextToolbar = document.getElementsByTagName("trix-toolbar")[0];
  if (button) {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      richTextToolbar.classList.toggle("!block");
    });
  }
});
