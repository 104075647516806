document.addEventListener("turbolinks:load", function () {
  const answers = document.getElementsByClassName("answer");
  const checkmark = document.createElement("p");
  checkmark.setAttribute("class", "absolute right-2 top-4");
  Array.from(answers).forEach((answer) => {
    answer.addEventListener("click", () => {
      const isCorrect = answer.getAttribute("data-is-correct");
      if (isCorrect === "true") {
        checkmark.innerHTML = '<i class="fa-solid fa-check text-green"></i>';
        answer.append(checkmark);
        return;
      }

      checkmark.innerHTML = '<i class="fa-solid fa-xmark text-red"></i>';
      answer.append(checkmark);
    });
  });
});
