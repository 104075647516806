document.addEventListener("turbolinks:load", function () {
  const btnSidebar = document.getElementById("btn-sidebar");
  const sidebar = document.getElementById("sidebar");
  const sidebarLayer = document.getElementById("sidebarLayer");
  btnSidebar.addEventListener("click", () => toggleSidebarClass());
  sidebarLayer.addEventListener("click", () => toggleSidebarClass());
  document.addEventListener("keyup", function (e) {
    if (e.key == "Escape") {
      toggleSidebarClass();
    }
  });

  function toggleSidebarClass() {
    btnSidebar.classList.toggle("text-white");
    sidebar.classList.toggle("show");
    sidebarLayer.classList.toggle("hidden");
  }
});
